import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import ReactPaginate from 'react-paginate';
import * as XLSX from 'xlsx';
import data from './lists.json'; // Import the JSON data
import './App.css'; // Import the custom CSS
import { FaFileDownload, FaPrint } from 'react-icons/fa'; // Import icons

function App() {
  const [password, setPassword] = useState('');
  const [accessGranted, setAccessGranted] = useState(false);
  const [searchTerm, setSearchTerm] = useState(''); // State for search input
  const [selectedRows, setSelectedRows] = useState([]); // State for selected rows
  const storedPassword = process.env.REACT_APP_PASSWORD; // Retrieve the actual password from .env

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Compare entered password with the password from .env
    if (password === storedPassword) {
      setAccessGranted(true);
    } else {
      alert('Incorrect password. Please try again.'); // Alert for incorrect password
      setPassword(''); // Clear the password field
      setAccessGranted(false); // Ensure access is denied
    }
  };

  // Pagination States
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 5; // Change this value to display more items per page
  const pageCount = Math.ceil(data.length / itemsPerPage);

  const handlePageClick = (event) => {
    const selectedPage = event.selected;
    setCurrentPage(selectedPage);
  };

  // Data to Display on Current Page
  const offset = currentPage * itemsPerPage;
  const currentPageData = data
    .filter(item => item.name.toLowerCase().includes(searchTerm.toLowerCase())) // Filter based on search term
    .slice(offset, offset + itemsPerPage);

  // Download Data as XLSX
  const downloadXLSX = () => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Data');
    XLSX.writeFile(workbook, 'data.xlsx');
  };

  // Logout Functionality
  const handleLogout = () => {
    setAccessGranted(false);
    setPassword(''); // Clear the password field
  };

  // Handle Row Selection
  const handleSelectRow = (id) => {
    setSelectedRows((prev) =>
      prev.includes(id) ? prev.filter((rowId) => rowId !== id) : [...prev, id]
    );
  };

  // Print Selected Rows
  const handlePrint = () => {
    const selectedData = currentPageData.filter(item => selectedRows.includes(item.id));
    const printWindow = window.open('', '_blank');
    printWindow.document.write('<html><head><title>Print</title></head><body>');
    printWindow.document.write('<h1>Selected Data</h1>');
    printWindow.document.write('<table border="1"><thead><tr><th>ID</th><th>Name</th><th>Phone</th></tr></thead><tbody>');

    selectedData.forEach(item => {
      printWindow.document.write(`<tr><td>${item.id}</td><td>${item.name}</td><td>${item.phone}</td></tr>`);
    });

    printWindow.document.write('</tbody></table></body></html>');
    printWindow.document.close();
    printWindow.print();
  };

  return (
    <div className="container mt-5">
      {!accessGranted ? (
        <div className="row justify-content-center">
          <div className="col-md-4">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title text-center">Enter Password to Access</h5>
                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <label htmlFor="password" className="form-label">Password</label>
                    <input
                      type="password"
                      id="password"
                      className="form-control"
                      value={password}
                      onChange={handlePasswordChange}
                      required
                      placeholder="Enter your password"
                    />
                  </div>
                  <button type="submit" className="btn btn-primary w-100">Submit</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <h2 className="text-center">Data Center</h2>
          <input
            type="text"
            className="form-control mb-3"
            placeholder="Search by name..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <div className="d-flex justify-content-between mb-3">
            <div>
              <button className="btn btn-outline-success" onClick={downloadXLSX}>
                <FaFileDownload /> Download Data (XLSX)
              </button>
              <button className="btn btn-outline-info ms-2" onClick={handlePrint}>
                <FaPrint /> Print Selected
              </button>
            </div>
            <button className="btn btn-outline-danger" onClick={handleLogout}>Logout</button>
          </div>
          <div className="card shadow mb-4">
            <div className="card-body">
              <div className="table-responsive"> {/* Responsive table wrapper */}
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>Select</th> {/* Add a header for selection */}
                      <th>ID</th>
                      <th>Name</th>
                      <th>Address</th>
                      <th>Phone</th>
                      <th>Rating</th>
                      <th>Link</th>
                      <th>Category</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentPageData.map(item => (
                      <tr key={item.id}>
                        <td>
                          <input
                            type="checkbox"
                            checked={selectedRows.includes(item.id)}
                            onChange={() => handleSelectRow(item.id)}
                          />
                        </td>
                        <td>{item.id}</td>
                        <td>{item.name}</td>
                        <td>{item.address}</td>
                        <td>{item.phone}</td>
                        <td>{item.rating}</td>
                        <td>{item.url}</td>
                        <td>{item.category}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div> {/* End of table-responsive */}
              <div className="d-flex justify-content-center">
                <ReactPaginate
                  previousLabel={'Previous'}
                  nextLabel={'Next'}
                  breakLabel={'...'}
                  breakClassName={'break-me'}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={handlePageClick}
                  containerClassName={'pagination'}
                  subContainerClassName={'pages pagination'}
                  activeClassName={'active'}
                  previousLinkClassName={'page-link'}
                  nextLinkClassName={'page-link'}
                  pageLinkClassName={'page-link'}
                  breakLinkClassName={'page-link'}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
